import React from 'react'

import BaseSvg from './BaseSvg'

export default function PhoneIcon() {
  return (
    <BaseSvg viewBox="0 0 32 40" size="3em">
      <path d="M23.911 19.714c-.38-.38-.89-.588-1.44-.588-.968 0-1.85.622-2.196 1.548l-.2.537a15.925 15.925 0 01-9.285-9.287l.535-.2a2.37 2.37 0 001.498-1.719c.156-.72-.039-1.418-.537-1.916l-1.001-1.001c-.79-.791-2.239-.74-3.085.105-1.63 1.631-2.146 4.047-1.285 6.014l.05.112a22.708 22.708 0 0011.714 11.715l.114.051a4.973 4.973 0 002.01.415c1.464 0 2.923-.619 4.003-1.7.42-.42.666-.968.691-1.542a2.022 2.022 0 00-.585-1.542l-1-1.002zm.188 3.379c-1.315 1.316-3.344 1.76-4.903 1.077l-.114-.05A21.713 21.713 0 017.88 12.916l-.049-.111c-.698-1.593-.265-3.564 1.076-4.906.255-.255.595-.4.932-.4.286 0 .548.104.739.295l1.001 1c.253.254.35.616.267.997a1.39 1.39 0 01-.87.995l-1 .373a.5.5 0 00-.298.632 16.934 16.934 0 0010.53 10.53.501.501 0 00.632-.298l.373-1c.197-.529.714-.898 1.259-.898.199 0 .488.05.732.295l1.002 1.002c.202.203.307.483.293.79a1.332 1.332 0 01-.4.88z" />
      <path d="M16 1.5C8.005 1.5 1.5 8.005 1.5 16S8.005 30.5 16 30.5 30.5 23.995 30.5 16 23.995 1.5 16 1.5zm0 28C8.556 29.5 2.5 23.444 2.5 16S8.556 2.5 16 2.5 29.5 8.556 29.5 16 23.444 29.5 16 29.5z" />
    </BaseSvg>
  )
}
